// import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { BannerImageI } from '../../Interface/HomepageCollectionsInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENT_BANNER_CLICK } from '../../utils/we';
import Button from '../NewButton/Button';
import {
  ActionSection,
  BottomOverlay,
  CarouselItemContainer,
} from './HeroGridCarousel.styles';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

const HeroGridCarouselItem = ({
  data,
  index,
}: {
  data: BannerImageI;
  index: number;
}) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  return (
    <CarouselItemContainer
      onClick={() => {
        WE_USER_EVENT_BANNER_CLICK(
          {
            bannerName: data.title,
            bannerUrl: data.link,
          },
          cleverTap
        );
        saveEventV3({
          category: 'banner',
          action: 'click',
          label: 'carousel',
          properties: 'main_banner',
          value: [data.title],
          from: router,
          jsonData: {
            clickIndex: index,
          },
        });
      }}
    >
      <Link href={data.link} prefetch={false} passHref>
        <a>
          <Image
            src={data.imageUrl}
            id="carousel-banner-image"
            alt={data?.title}
            layout="fill"
            objectFit="cover"
            quality={50}
            priority={index === 0 || index === 1 || index === 2}
          />

          <ActionSection>
            <p>{data.title}</p>
            <Link href={data.link} prefetch={false} passHref>
              <Button
                height="48px"
                width="fit-content"
                style={{
                  minWidth: '148px',
                }}
                buttonTheme="white"
              >
                {JSON.parse(data?.metadata || null)?.btnText || 'Buy Now'}
              </Button>
            </Link>
          </ActionSection>
          <BottomOverlay />
        </a>
      </Link>
    </CarouselItemContainer>
  );
};

export default memo(HeroGridCarouselItem);
